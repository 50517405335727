@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        font-family: 'DM Sans', system-ui, sans-serif;
    }

    body {
        @apply bg-light-bg min-h-screen;
    }
}

.poppins-regular {
    font-family: "Poppins", serif;
    font-weight: 400;
    font-style: normal;
}

.poppins-bold {
    font-family: "Poppins", serif;
    font-weight: 700;
    font-style: normal;
}

.gradient-line {
    background: linear-gradient(90deg, #6942fe 0%, #33aa63 100%);
}

/* Add to your index.css */
@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes slideInUp {
    0% {
        transform: translateY(20px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slideInRight {
    0% {
        transform: translateX(-20px);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideInLeft {
    0% {
        transform: translateX(20px);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.animate-fadeIn {
    animation: fadeIn 0.8s ease-out forwards;
}

.animate-slideInUp {
    animation: slideInUp 0.8s ease-out forwards;
}

.animate-slideInRight {
    animation: slideInRight 0.8s ease-out forwards;
}

.animate-slideInLeft {
    animation: slideInLeft 0.8s ease-out forwards;
}

.delay-100 {
    animation-delay: 100ms;
}

.delay-200 {
    animation-delay: 200ms;
}

.delay-300 {
    animation-delay: 300ms;
}

.delay-400 {
    animation-delay: 400ms;
}

/* Add a hover effect for the gradient line */
.gradient-line {
    background: linear-gradient(90deg, #6942fe 0%, #33aa63 100%);
    transition: width 0.5s ease-in-out;
}

.hover-expand:hover .gradient-line {
    width: 80px;
}

/* Subtle pulse animation for the time display */
@keyframes pulse {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.8;
    }

    100% {
        opacity: 1;
    }
}

.time-pulse {
    animation: pulse 2s infinite;
}